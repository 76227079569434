import { Directive, ElementRef, HostListener, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { CountUp } from 'countup.js';
import { Odometer } from 'odometer_countup';

/**
 * https://github.com/inorganik/countUp.js
 * https://github.com/msoler75/odometer_countup.js
 */

@Directive({
    selector: '[odometer]',
    standalone: true,
})
export class OdometerDirective {
    counter!: CountUp
    @Input('value') value!:number | string;
    constructor(
        private elementRef: ElementRef,
    ) {

    }
  
    ngOnInit() {
        const el = this.elementRef.nativeElement
        this.counter = new CountUp(el, Number(this.value), {
            startVal: 0,
            plugin: new Odometer({ duration: 1, lastDigitDelay: 0 }),
            duration: 1.0,
            useGrouping: true,
            
        })
        this.counter.start()
    }

    ngOnDestroy() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['value']) {
            const prev = changes['value'].previousValue;
            const current = changes['value'].currentValue
            if(current) {
                if(this.counter) this.counter.update(current)
            }
        }
    }
}