import { Component } from '@angular/core';
import { ModalService } from '../../common/modal/modal.service';
import { OdometerDirective } from '@app/shared/lib/directive/odometer.directive';
import { Observable, Subscription, timer } from 'rxjs';
import { LoadingComponent } from '../../common/loading/loading.component';
import { ApiService } from '../../service/api.service';
import { CommonModule } from '@angular/common';
import moment from 'moment-timezone';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { DailyPrizeService } from '../../service/daily-prize.service';
import { MomentPipe } from '@app/shared/lib/pipe/moment.pipe';
import { ProfileDirective } from '@app/shared/lib/directive/profile.directive';

@Component({
    selector: 'app-promotion-contest',
    standalone: true,
    imports: [
        CommonModule,
        LoadingComponent,
        OdometerDirective,
        MomentPipe,
        ProfileDirective
    ],
    templateUrl: './promotion-contest.component.html',
    styles: ``
})
export class PromotionContestComponent {
    private subs: Subscription[] = [];
    
    currentUser!:currentUserInfo | null;
    everySecond: Observable<number> = timer(0, 1000);

    hours!:string
    minutes!:string
    seconds!:string

    currentDate!:string
    total_bet!:number
    rank!:number

    daily_prize:number = 0
    
    isLoading:boolean = true;

    currentItems:any
    winnerItems:any;

    medalImg = [
        '/assets/custom_img/chat_gold.svg',
        '/assets/custom_img/chat_silver.svg',
        '/assets/custom_img/chat_copper.svg',
    ]

    get modal() { return this.modalService }
    constructor(
        private modalService: ModalService,
        private apiService: ApiService,
        private authService: AuthService,
        private dailyPrizeService: DailyPrizeService
    ) {}

    ngOnInit() {
        this.subs.push(
            this.everySecond.subscribe((v) => {
                
                const remain = moment.tz('Asia/Seoul').hours(24).minutes(0).seconds(0)
                const timeLeft = remain.diff(moment(), 'seconds')

                const second = 1,
                minute = second * 60,
                hour = minute * 60,
                day = hour * 24;

                this.hours = Math.floor((timeLeft % (day)) / hour).toString();
                this.minutes = Math.floor((timeLeft % (hour)) / minute).toString();
                this.seconds = Math.floor((timeLeft % (minute)) / second).toString();
            })
        )

        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )

        this.subs.push(
            this.dailyPrizeService.getData().subscribe((v:number) => {
                this.daily_prize = v;
            })
        )
        
        this.fnGetData()
    }

    ngDestory() {
        this.subs.map(s => s.unsubscribe());
    }

    fnGetData() {
        this.isLoading = true;
        this.apiService.get(`/promotion/contest`, false).subscribe({
            next: (jsondata) => {
                if(jsondata.success) {
                    this.rank = jsondata.data.rank;
                    this.total_bet = jsondata.data.total_bet;
                    this.currentDate = jsondata.data.current.date;
                    if(jsondata.data.current.items.length > 0) {
                        this.currentItems = jsondata.data.current.items;
                    }
                    
                    this.winnerItems = jsondata.data.winner;
                }
                this.isLoading = false;
            }, error: (err) => {
                this.isLoading = false;
            }
        })
    }

    MyRanking() {
        if(this.rank) {
            if(this.rank == 1) return `1st`;
            else if(this.rank == 2) return `2nd`;
            else if(this.rank == 3) return `3rd`;
            else return `${this.rank}th`;
        } else return `50th+`
    }

    GapRolling() {
        if(!this.currentItems) return ''
        
        if(!this.rank || this.rank > 10) return this.currentItems[this.currentItems.length -1].total_bet
        else ''
    }

    RaceRank() {
        if(!this.currentItems) return '';
        
        const rank = this.currentItems[this.currentItems.length -1].rank
        if(rank == 1) return `1st`;
        else if(rank == 2) return `2nd`;
        else if(rank == 3) return `3rd`;
        else return `${rank}th`
    }
}


