var __assign = this && this.__assign || function () {
    return (__assign = Object.assign || function (t) {
      for (var e, n = 1, i = arguments.length; n < i; n++) for (var r in e = arguments[n]) Object.prototype.hasOwnProperty.call(e, r) && (t[r] = e[r]);
      return t;
    }).apply(this, arguments);
  },
  rAF = window.requestAnimationFrame || function (t) {
    window.setTimeout(t, 1e3 / 60);
  },
  Odometer = function () {
    function t(t) {
      this.version = "1.0", this.defaults = {
        duration: .8,
        lastDigitDelay: .25
      }, this.cell_digits = null, this.options = __assign(__assign({}, this.defaults), t), this.cell_digits = null;
    }
    return t.prototype.render = function (t, o) {
      for (var e, s = this.options, n = !1, a = (this.cell_digits || (n = !0, document.querySelector("style[odometer]") || ((e = document.createElement("style")).setAttribute("odometer", "odometer"), e.innerHTML = ".odometer-numbers{display:inline-flex;line-height:100%;overflow-y:hidden}.odometer-numbers>span{display:flex;flex-direction:column;justify-content:start;align-items:center;height:1em;will-change:transform;transform:translateY(0)}", document.head.appendChild(e)), t.innerHTML = '<div class="odometer-numbers"></div>', this.cell_digits = []), '<span style="color:transparent">0</span>'), l = "transform ".concat(s.duration, "s ease-out"), d = this.cell_digits.length; d < o.length; d++) {
        var i = document.createElement("span");
        i.style.transition = l, i.innerHTML = n ? "" : a, t.firstChild && t.firstChild.appendChild(i), this.cell_digits.push({
          container: i,
          current: void 0,
          position: n ? 1 : 0,
          new: !0
        });
      }
      function c(t, e) {
        t.position--, t.container.appendChild(e), t.lastTimeAdd = +new Date(), t.new ? (t.new = !1, rAF(function () {
          t.container.style.transform = "translateY(".concat(t.position, "em)");
        })) : t.container.style.transform = "translateY(".concat(t.position, "em)");
      }
      function r() {
        u = d < o.length ? o.charAt(d) : null;
        var t,
          e,
          n,
          i,
          r = f.cell_digits[d];
        r.current != u && (r.current = u, (m = document.createElement("span")).innerHTML = null === u ? a : u, r.container.children.length < 4 ? c(r, m) : (e = m, (t = r).nextToAdd && (c(t, t.nextToAdd), clearTimeout(t.lastTimer), t.nextToAdd = null), n = +new Date(), i = 1e3 * s.lastDigitDelay - (n - t.lastTimeAdd), s.lastDigitDelay <= 0 || n - t.lastTimeAdd >= 1.05 * i ? (c(t, e), t.nextToAdd = null) : (t.nextToAdd = e, t.lastTimer = setTimeout(function () {
          c(t, t.nextToAdd), t.nextToAdd = null;
        }, 1e3 * s.duration))), clearTimeout(r.timerClean), r.timerClean = setTimeout(function () {
          r.timerClean = null, r.container.children.length < 3 || (r.container.style.transition = "none", rAF(function () {
            for (r.position = -1; 1 < r.container.children.length;) r.container.removeChild(r.container.firstChild);
            var t = document.createElement("span");
            t.innerHTML = a, r.container.insertBefore(t, r.container.firstChild), r.container.style.transform = "translateY(".concat(r.position, "em)"), rAF(function () {
              r.container.style.transition = l;
            });
          }));
        }, 1e3 * ((s.duration || .8) + (s.duration || .25)) + 2500));
      }
      for (var u, m, h = Math.max(o.length, this.cell_digits.length), f = this, d = 0; d < h; d++) r();
    }, t;
  }();
export { Odometer };