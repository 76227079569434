import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DailyPrizeService {
    private subject = new BehaviorSubject<number>(0)
    
    constructor(
        private apiService: ApiService
    ) {
        this.onInit()
    }

    public getData() {
        return this.subject.asObservable() 
    }


    private onInit() {
        this.apiService.get('/promotion/daily_prize', false).subscribe(jsondata => {
            if(jsondata.success) {
                this.subject.next(jsondata.data.daily_prize)
            }
        })
    }
}